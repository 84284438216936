import './themeToggle.css';

function ThemeToggle({ onToggle, isDarkMode }) {
    return (
      <button className={`mode ${isDarkMode ? 'dark' : 'light'}`} onClick={onToggle}>
        {isDarkMode ? 
        <><span className='toolbar-emoji'>🌞</span>
        <span className='toolbar-text'> Light Mode</span> </> :     
        <><span className='toolbar-emoji'>🌙</span>
        <span className='toolbar-text'> Dark Mode</span> </>}
      </button>
    );
  }

export default ThemeToggle;