import React, { useEffect, useState } from 'react';
import './HintButton.css';

const HintButton = ({ level, onRevealHint, hints, isDarkMode, hintStatus }) => {
  const [revealedHints, setRevealedHints] = useState({
    year: hintStatus.year,
    genre: hintStatus.genre,
    director: hintStatus.director,
    mainActor: hintStatus.mainActor,
  });

  // Synchronize revealedHints with hintStatus when hintStatus changes
  useEffect(() => {
    setRevealedHints({
      year: hintStatus.year,
      genre: hintStatus.genre,
      director: hintStatus.director,
      mainActor: hintStatus.mainActor,
    });
  }, [hintStatus]); 
  
  const handleClick = (hint) => {
    if (!revealedHints[hint]) {
      setRevealedHints({ ...revealedHints, [hint]: true });
      onRevealHint(level, hint);
    }
  };

  return (
    <div className="hint-container">
      <button
        className={`hint-button ${isDarkMode ? 'dark-mode' : ''} enabled`}
        onClick={() => handleClick('year')}
      >
        {revealedHints.year ? hints.year : '🗓️'}
      </button>
      <button
        className={`hint-button ${isDarkMode ? 'dark-mode' : ''} enabled`}
        onClick={() => handleClick('genre')}
      >
        {revealedHints.genre ? hints.genre : '🎭'}
      </button>
      <button
        className={`hint-button ${isDarkMode ? 'dark-mode' : ''} enabled`}
        onClick={() => handleClick('director')}
      >
        {revealedHints.director ? hints.director : '🎬'}
      </button>
      <button
        className={`hint-button ${isDarkMode ? 'dark-mode' : ''} enabled`}
        onClick={() => handleClick('mainActor')}
      >
        {revealedHints.mainActor ? hints.mainActor : '🌟'}
      </button>
    </div>
  );
};

export default HintButton;