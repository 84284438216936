import React, { useState } from 'react';
import StatsModal from './StatsModal';
import './themeToggle.css';

const HowToPlayButton = ({isDarkMode, stats}) => {
    const [isStatsButtonOpen, setStatsButtonOpen] = useState(false);
    const toggleStats = () => {
        setStatsButtonOpen(!isStatsButtonOpen);
    };

    return (
      <div>
        <button className={`mode ${isDarkMode ? 'dark' : 'light'}`} onClick={toggleStats}>
        <span className='toolbar-emoji'>📊</span>
        <span className='toolbar-text'> Stats</span>
        </button>
        {isStatsButtonOpen && <StatsModal onClose={toggleStats} stats={stats} isDarkMode={isDarkMode} />}
      </div>
    );
  }

export default HowToPlayButton;