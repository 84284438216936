import React, { useEffect, useState } from 'react';
import './LoadingScreen.css'; // Import the CSS styles

const LoadingScreen = ({ loading }) => {
const [shouldRender, setShouldRender] = useState(true); // Keep the component rendered during the animation
  
  useEffect(() => {
    let timer;
    if (!loading) {
      const loadingScreen = document.querySelector('.loading-container');
      if (loadingScreen) {
        loadingScreen.classList.add('fade-out');
      // Wait for the fade-out animation to complete before removing the loading screen
      timer = setTimeout(() => {
        setShouldRender(false);  // Remove the loading screen after the animation completes
      }, 3000);  // Match this with the duration of your CSS transition (0.5s)
      }
      return () => clearTimeout(timer);  // Clean up the timer on component unmount
    }
  }, [loading]);

    // Don't render the component after the animation has completed
    if (!shouldRender) return null;

  return (
    <div className="loading-container">
      <div className="loading-spinner"></div>
      <p>Loading quiz...</p>
    </div>
  );
};

export default LoadingScreen;
