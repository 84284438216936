import React from 'react';
import './StyledInput.css';

const StyledInput = ({ value, onChange, onKeyDown, placeholder, isDarkMode }) => {
  return (
    <div className="input-container">
      <input
        type="text"
        className={`styled-input ${isDarkMode ? "dark-mode" : ""}`}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
      />
    </div>
  );
};

export default StyledInput;
