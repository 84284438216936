import React from 'react';
import './ThemeDisplay.css';

function ThemeDisplay({ theme, isDarkMode, date }) {
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  const adjustedDate = new Date(date);
  const formattedDate = adjustedDate.toLocaleDateString('en-US', options);

  return (
    <div className={`theme-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className={`theme-header ${isDarkMode ? 'dark-mode' : ''}`}>THEME OF THE DAY | {formattedDate}</div>
      <div className={`theme-content ${isDarkMode ? 'dark-mode' : ''}`}>{theme}</div>
    </div>
  );
}

export default ThemeDisplay;
