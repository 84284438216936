import React from 'react';
import './RankingSystem.css';

function RankingSystem({ gamesWon, isDarkMode }) {
  const ranks = [
    { level: "🐣 Movie Novice", score: 0 },
    { level: "🍿 Casual Viewer", score: 20 },
    { level: "🎬 Movie Buff", score: 40 },
    { level: "📽️ Film Enthusiast", score: 60 },
    { level: "🎥 Cinephile", score: 80 }
  ];

  ranks.sort((a, b) => b.score - a.score);
  const currentRank = ranks.find(rank => gamesWon >= rank.score);
  ranks.sort((a, b) => a.score - b.score);
  const nextRank = ranks.find(rank => gamesWon < rank.score);

  const pointsToNextRank = nextRank ? nextRank.score - gamesWon : null;

  return (
    <div className="ranking-system">
      <h3>Rank (by win%)</h3>
      {ranks.map((rank, index) => (
        <div
          key={index}
          className={`rank ${rank.level === currentRank.level ? 'current-rank' : ''} ${isDarkMode? 'dark-mode' : ''}`}
        >
          <span className="rank-level">{rank.level}</span>
          <span className="rank-score">{'>'}{rank.score}%</span>
        </div>
      ))}
      <div className="current-rank-details">
        <span className="current-rank-level">{currentRank.level}</span>
        <span className="points-to-next-rank">
          {pointsToNextRank !== null
            ? `(${pointsToNextRank}% to next rank, ${nextRank.level})`
            : 'Max rank achieved'}
        </span>
      </div>
    </div>
  );
}

export default RankingSystem;
