import React from 'react';
import RankingSystem from './RankingSystem';
import './Modal.css';

const StatsModal = ({ onClose, stats, isDarkMode }) => {
    const winPercentage = (stats?.gamesPlayed > 0) ? Math.round((stats?.gamesWon / stats?.gamesPlayed) * 100) : 0;
    const avgHintsUsed = Math.round(stats?.hintsUsed / stats?.gamesPlayed ?? 1);
    const avgMistakesMade = Math.round(stats?.mistakesMade / stats?.gamesPlayed ?? 1);

    return (
        <div className="modal-overlay">
        <div className="modal-content" overlayclassname="overlay">
            <button className="close-button" onClick={onClose}>×</button>
            <div className="stats-header">
                <h2>m🙂jie Statistics (beta)</h2>
            </div>
            <div className="stats-content">
                <div className="stat">
                    <div className="stat-value">{stats?.gamesPlayed || 0}</div>
                    <div className="stat-label">Played</div>
                </div>
                <div className="stat">
                    <div className="stat-value">{winPercentage || 0}</div>
                    <div className="stat-label">Win %</div>
                </div>
                <div className="stat">
                    <div className="stat-value">{avgHintsUsed || 0}</div>
                    <div className="stat-label">Avg Hints Used</div>
                </div>
                <div className="stat">
                    <div className="stat-value">{avgMistakesMade || 0}</div>
                    <div className="stat-label">Avg Mistakes Made</div>
                </div>
            </div>
            <br/>
            <div>
                <RankingSystem gamesWon={winPercentage} isDarkMode={isDarkMode} />
            </div>
        </div>
        </div>
  );
};

export default StatsModal;
