import React, { useState, useEffect } from 'react';
import HowToPlayModal from './HowToPlayModal';
import './themeToggle.css';

const HowToPlayButton = ({isDarkMode, showHowToPlay}) => {
    const [isHowToPlayOpen, setIsHowToPlayOpen] = useState(showHowToPlay);
    const toggleHowToPlay = () => {
        setIsHowToPlayOpen(!isHowToPlayOpen);
      };

    useEffect(() => {
        setIsHowToPlayOpen(showHowToPlay)
    }, [showHowToPlay]);

    return (
      <div>
        <button className={`mode ${isDarkMode ? 'dark' : 'light'}`} onClick={toggleHowToPlay}>
        <span className='toolbar-emoji'>🧐</span>
        <span className='toolbar-text'> How to Play</span>
        </button>
        {isHowToPlayOpen && <HowToPlayModal onClose={toggleHowToPlay} />}
      </div>
    );
  }

export default HowToPlayButton;