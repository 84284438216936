import React, { useState } from 'react';
import './Modal.css';

const RevealModal = ({ isOpen, onClose, level, movie, onGuess, questionStatus, isDarkMode}) => {
  const [guess, setGuess] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  if (!isOpen) return null;

  const handleReveal = async () => {
    setLoading(true);
    questionStatus.status = 'revealed';
    onClose();
    await onGuess(guess, level);
    setLoading(false);
    setGuess('');
  };

  const square = {
    easy: "🟩",
    medium:"🟦",
    hard:"🟪",
  }

  return (
    <div className={`reveal-modal-overlay ${isDarkMode} ? 'dark-mode' : ''`}>
    <div className="reveal-modal-content">
      <button className="close-button" onClick={onClose}>×</button>
      <h3>Reveal answer for {square[level]} ({level}) level movie?</h3>
      <button className={"share-results-button"} onClick={handleReveal}>Reveal 🔍</button> 
    </div>
  </div>
  );
};

export default RevealModal;
