import { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the date picker styles
import './DatePicker.css'; // Import the custom date picker styles

const DatePickerModal = ({ onClose, onDateChange, isDarkMode, completedQuizDates, date }) => {
    const [selectedDate, setSelectedDate] = useState(date);
    const [formattedDate, setFormattedDate] = useState(date);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        const formattedDate = date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        setFormattedDate(formattedDate);
      };

    const handleSubmit = () => {
      if (formattedDate) {
        onDateChange(formattedDate);  // Pass the formatted date to the parent component
        onClose();  // Close the modal
      }
    };

    const { DateTime } = require("luxon");
    const minDate = new Date('2024-06-02');
    const maxDate = DateTime.utc().toFormat('MM/dd/yyyy');;

    const formattedSelectedDate = typeof selectedDate === 'string' ? new Date(selectedDate) : selectedDate;

    return (
        <div className={isDarkMode ? 'dark-mode' : ''}>
            <div className="date-picker-modal">
                <div className="close-container">
                    <button className="modal-close-button" onClick={onClose}>×</button>
                </div>
                <DatePicker
                    selected={formattedSelectedDate || new Date(maxDate)}
                    onChange={handleDateChange}
                    minDate={minDate}  // Limit selection to May 31, 2024, or later
                    maxDate={maxDate}  // Limit selection to today or earlier
                    dateFormat="MM/dd/yyyy"
                    showMonthDropdown  // Show month dropdown
                    showYearDropdown   // Show year dropdown
                    dropdownMode="select"  // Makes month/year dropdowns more customizable
                    inline  // Keeps the calendar always open
                    openToDate={formattedSelectedDate || new Date(maxDate)}
                    // dayClassName={date => {
                    //     const formattedDate = date.toLocaleDateString("en-US", {
                    //         year: "numeric",
                    //         month: "2-digit",
                    //         day: "2-digit",
                    //     });
                    //     return completedQuizDates.includes(formattedDate) ? "completed-quiz-date" : "";
                    // }}
                    dayClassName={date => {
                        // Format the date for comparison with completedQuizDates
                        const formattedDate = date.toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        });
                    
                        // Get today's date in UTC
                        const todayUtc = DateTime.utc().startOf('day');  
                        const currentDayUtc = DateTime.fromJSDate(date).toUTC().startOf('day');
                    
                        // Check if the day is 'today' in UTC and if it is in the completed quiz list
                        if (currentDayUtc.toISODate() === todayUtc.toISODate()) {
                          return "react-datepicker__day--today-utc";
                        }
                        
                        // Mark completed quizzes
                        return completedQuizDates.includes(formattedDate) ? "completed-quiz-date" : "";
                      }}
                />
                <button className='button-date-picker' onClick={handleSubmit}>Submit</button>
                <div className="date-legend">
                    <h5>🟦 Selected | 🟨 Attempted | 🔲 Today</h5>
                    <h5>Quiz attempts begin saving Sep 20th, 2024.</h5>
                </div>
            </div>
        </div>
    );
};

export default DatePickerModal;
