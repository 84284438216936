import { useState } from 'react';
import DatePickerModal from './DatePickerModal';
import './themeToggle.css';

const CalendarButton = ({ isDarkMode, handleDateChange, completedQuizDates, date }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <button className={`mode ${isDarkMode ? 'dark' : 'light'}`} onClick={openModal}>
        <span className='toolbar-emoji'>🗓️</span>
        <span className='toolbar-text'> Past Quizzes</span>
        </button>

      {isModalOpen && (
        <DatePickerModal onClose={closeModal} onDateChange={handleDateChange} isDarkMode={isDarkMode} completedQuizDates={completedQuizDates} date={date} />
      )}
    </>
  );
};

export default CalendarButton;
