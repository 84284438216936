import React, { useEffect, useState } from 'react';
import './Modal.css';

const ResultsModal = ({ isOpen, onClose, results, onCopy, theme, date, isDarkMode, mistakes, answers, hints, updateFeedback}) => {
  const [buttonText, setButtonText] = useState('Share Your Results');
  const [countdown, setCountdown] = useState('');
  const [feedback, setFeedback] = useState(false);

  useEffect(() => {
    const updateCountdown = () => {
      const timeLeft = getTimeUntilNextDayUTC();
      setCountdown(formatTime(timeLeft));
    };

    updateCountdown();
    const intervalId = setInterval(updateCountdown, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setButtonText('Share Your Results');
  }, [onClose]);

  if (!isOpen) return null;

  const handleLike = async () => {
    if (!feedback){
      await updateFeedback(1, 0, date);
      setFeedback(true);
    }
  };
  const handleDislike = async () => {
    if (!feedback){
      await updateFeedback(0, 1, date);
      setFeedback(true);
    }
  };

  const handleCopy = () => {
    onCopy();
    setButtonText('Results Copied!');
  };
  const countCheckEmojis = (str) => {
    return (str.split('✅').length - 1);
  };

  function getTimeUntilNextDayUTC() {
    const now = new Date();
    const utcNow = new Date(now.toISOString());
    const nextDayUTC = new Date(Date.UTC(utcNow.getUTCFullYear(), utcNow.getUTCMonth(), utcNow.getUTCDate() + 1));
    return nextDayUTC - utcNow;
  }
  
  function formatTime(ms) {
    const totalSeconds = Math.floor(ms / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }
  
  return (
    <div className={`modal-overlay ${isDarkMode} ? 'dark-mode' : ''`}>
    <div className="modal-content">
      <button className="close-button" onClick={onClose}>×</button>
      <h1>m🙂jie</h1>
      <p>{date}</p>
      <p>"{theme}"</p>
      <div className="results-emoji">
        {results}
      </div>
      <p className="result-message">
        Thanks for playing!<br/>
        You used {hints} hint(s) <span role="img" aria-label="lightbulb">💡</span>, <br/>
        you made {mistakes} mistake(s) ⚪️, <br/>
        and you got {countCheckEmojis(results)} movie(s) correct ✅!
      </p>
      <div className="countdown">
            <p className="heading">NEXT PUZZLE IN</p>
            <h1 className="countdown-timer">{countdown}</h1>
      </div>
      <button className="share-results-button" onClick={handleCopy}>{buttonText}</button>

      <div className="feedback">
      <p className={`feedback-title ${isDarkMode ? 'dark' : 'light'}`}>Quiz Feedback:</p>
      {!feedback ? <div className="feedback-buttons">
        <button className="feedback-button" onClick={handleLike}>
          👍
        </button>
        <button className="feedback-button" onClick={handleDislike}>
          👎
        </button>
      </div> : " 🙏🥳🙏 "}
      </div>
    </div>
  </div>
  );
};

export default ResultsModal;
