import './Toolbar.css';
import HowToPlayButton from './HowToPlayButton';
import StatsButton from './StatsButton';
import ThemeToggle from './themeToggle';
import CalendarButton from './CalendarButton.js'; 

const Toolbar = ({ isDarkMode, onToggle, stats, showHowToPlay, handleDateChange, completedQuizDates, date }) => {
  return (
    <div className="toolbar">
      <div className = "toolbar-button"><ThemeToggle onToggle={onToggle} isDarkMode={isDarkMode} /></div>
      <div className = "toolbar-button"><HowToPlayButton isDarkMode={isDarkMode} showHowToPlay={showHowToPlay} /></div>
      <div className = "toolbar-button"><StatsButton isDarkMode={isDarkMode} stats={stats} /></div>
      <div className="toolbar-button"><CalendarButton isDarkMode={isDarkMode} handleDateChange={handleDateChange} completedQuizDates={completedQuizDates} date={date} /></div>
    </div>
  );
};

export default Toolbar;
