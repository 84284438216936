import React, { useState, useEffect } from 'react';
import './UpdateBanner.css';  // Create this CSS file for styling

const UpdateBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  // Check if the banner has already been dismissed
  useEffect(() => {
    const dismissed = localStorage.getItem('updateBannerDismissed');
    if (!dismissed) {
      setShowBanner(true);  // Show banner if not dismissed
    }
  }, []);

  // Dismiss the banner and store the dismissal in localStorage
  const dismissBanner = () => {
    setShowBanner(false);
    localStorage.setItem('updateBannerDismissed', 'true');
  };

  if (!showBanner) return null;  // Return null if the banner is dismissed

  return (
    <div className="update-banner">
      <div className="update-banner-content">
        <p>🚨 Check out 🗓️ Past Quizzes to access the entire mojie archive! 🎉</p>
        <button className="dismiss-btn" onClick={dismissBanner}>Dismiss</button>
      </div>
    </div>
  );
};

export default UpdateBanner;
